import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class EvaluationService {
	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
	) {}

	/**
	 * COMMON SERVICES
	 */

	isEvaluator(personId: any, periodId?: any, checkCurrent?: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};

			if (periodId) options.params = options.params.set("periodId", periodId);
			if (checkCurrent) options.params = options.params.set("checkCurrent", checkCurrent);

			const url = environment.urlDomain + "/api/v1/evaluation/isEvaluator";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationPeriods(type: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						type,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/periods";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationFormById(evalFormId, personId?, periodId?, evalId?, fromAdmin?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						evalFormId,
					},
				}),
			};

			if (personId) {
				options.params = options.params.set("personId", personId);
			}
			if (periodId) {
				options.params = options.params.set("periodId", periodId);
			}
			if (evalId) {
				options.params = options.params.set("evalId", evalId);
			}
			if (fromAdmin) {
				options.params = options.params.set("fromAdmin", fromAdmin);
			}

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationFormById";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationFormByIdPOLL(evalFormId, personId?, periodId?, evalId?, fromAdmin?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						evalFormId,
					},
				}),
			};

			if (personId) {
				options.params = options.params.set("personId", personId);
			}
			if (periodId) {
				options.params = options.params.set("periodId", periodId);
			}
			if (evalId) {
				options.params = options.params.set("evalId", evalId);
			}
			if (fromAdmin) {
				options.params = options.params.set("fromAdmin", fromAdmin);
			}

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationFormByIdPOLL";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluation(evalFormId, personId, periodId, iterationNumber): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
						evalFormId,
						iterationNumber,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluation";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getElementResultByEvaluation(id: any, type: any, evalId: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						id,
						type,
						evalId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/elementResult";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	sendAutoevaluationMail(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				personId,
			};

			const url = environment.urlDomain + "/api/v1/evaluation/sendAutoevaluationMail";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	saveEvaluation(body: any, type: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/saveEvaluation" + type;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	setEvaluationCheckedByUser(body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationChecked";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPlantillaIberext(evaluationId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				responseType: "blob" as "json", // Convertir a 'json' para la asignación de tipos
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getPlantillaIberext/" + evaluationId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 *   EVALUATION DASHBOARD
	 */

	getMainPeriod(date: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						type: "EVALUATION",
						moment: date,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/mainPeriod";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPreviousPeriod(date: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						type: "EVALUATION",
						moment: date,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/previousPeriod";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationForm(puestoId: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						puestoId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationForm";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluatorsForm(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluatorsForm";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAvgEvaluations(personId: any, date: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						moment: date,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/avgEvaluations";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllEvaluationsByPerson(personId, typeForm): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						typeForm,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allEvaluationsByPerson";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllEvaluationsByPersonSyrsa(personId, typeForm): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						typeForm,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allEvaluationsByPersonSyrsa";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPanelEvaluations(personId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/panelEvaluations";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationsPendings(periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationsPendings";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluators(personId, puestoId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						puestoId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluators";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationPartial(personId, evaluatorId, periodId, evalId?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						evaluatorId,
						periodId,
					},
				}),
			};

			if (evalId) {
				options.params = options.params.set("evalId", evalId);
			}

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationPartial";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 *   EVALUATION PANEL CONTROL
	 */

	getAllEvaluationPartial(status, periodId, personId, departamento?, puesto?, aspects?): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						status,
						periodId,
						personId,
					},
				}),
			};

			if (departamento) options.params = options.params.set("departament", departamento);
			if (puesto) options.params = options.params.set("puesto", puesto);
			if (aspects) {
				const aspectFilters = Object.keys(aspects)
					.filter((a) => aspects[a] !== "")
					.reduce((obj, key) => {
						obj[key] = aspects[key];
						return obj;
					}, {});
				if (Object.keys(aspectFilters).length) {
					options.params = options.params.set("aspects", JSON.stringify(aspectFilters));
				}
			}

			const url = environment.urlDomain + "/api/v1/evaluation/allEvaluationPartial";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllEvaluationPartialCurrent(periodId, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
						personId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allEvaluationPartialCurrent";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationAspects(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationAspects";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPersonAspects(personId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/userAspects";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	savePersonAspect(personId, periodId, aspectId, aspectValueId, aspectPersonId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				person_id: personId,
				period_id: periodId,
				aspect_id: aspectId,
				aspect_value_id: aspectValueId || "",
				aspect_person_id: aspectPersonId || "",
			};

			const url = environment.urlDomain + "/api/v1/evaluation/userAspect";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 *   ACTIONS PLANS
	 */

	getActionsPlans(personId, mainPeriodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						mainPeriodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlans";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllActionsPlans(personId, objetivos?: boolean): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};

			if (objetivos) options.params = options.params.set("objetivos", 1);

			const url = environment.urlDomain + "/api/v1/evaluation/allActionPlans";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getNextActionPlansBlue(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getNextActionPlansBlue";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getNextActionsPlans(personId, periodId, userId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
						userId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/nextActionPlans";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getHistoricoObjetivos(personId: number, limit: number, page: number, filtros?: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros?.periodo != null && filtros?.periodo !== "") options.params = options.params.set("periodo", filtros.periodo);

			const url = environment.urlDomain + `/api/v1/evaluation/getHistoricoObjetivos/${personId}/${limit}/${page}`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getActionPlanResults(actionPlanId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						actionPlanId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlanResults";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationActionPlanResults(actionPlanId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						actionPlanId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationActionPlanResults";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	saveActionPlanResult(apResult: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlanResult";
			this.http.put(url, apResult, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	saveActionPlan(ap: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan";
			this.http.post(url, ap, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	solicitarDisabledPDA(pdaId: number, estado: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/solicitarDisabledPDA/" + pdaId + "/" + estado;
			this.http.put(url, null, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	disablePDAnoApply(pdaId: number, estado: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/disablePDAnoApply/" + pdaId + "/" + estado;
			this.http.put(url, null, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getNotDonePDA(periodId: number, personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getNotDonePDA/" + periodId + "/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deleteActionPlan(id: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/" + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getActionPlanRevision(actionPlanId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/revision/" + actionPlanId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getActionPlanComments(actionPlanId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/comments/" + actionPlanId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	saveActionPlanComments(body): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/comments/";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	reviseActionPlan(body): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/revised/";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	lockActionPlan(body): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/actionPlan/lock/";
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 * EVALUATION TRANSFER
	 */

	isEvaluationTransfered(personId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationTransferedTo";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	transferEvaluation(personId, periodId, newEvaluatorId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				personId,
				periodId,
				newEvaluatorId,
			};

			const url = environment.urlDomain + "/api/v1/evaluation/transferEvaluation";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deleteTransfer(transferId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/transferEvaluation/" + transferId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	transferActionPlan(personId, actionPlanId, newPersonId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				personId,
				actionPlanId,
				newPersonId,
			};

			const url = environment.urlDomain + "/api/v1/evaluation/transferActionPlan";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	confirmarPDATransfer(respuesta, transferId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			let body = {
				confirmada: respuesta,
			};

			const url = environment.urlDomain + "/api/v1/evaluation/transferActionPlan/confirm/" + transferId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 * PRIVATE FEEDBACK
	 */

	getAllPrivateFeedbackRequestByPersonAndPeriod(personAboutId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personAboutId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allPrivateFeedbackRequestByPersonAndPeriod";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllPrivateFeedbackRequestByUserAndPeriod(periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allPrivateFeedbackRequestByUserAndPeriod";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getAllPrivateFeedbackResponseByUserAndPeriod(periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/allPrivateFeedbackResponseByUserAndPeriod";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPrivateFeedbackHistoryByUser(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/privateFeedbackHistoryByUser";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	setPrivateFeedback(body): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/privateFeedback";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deletePrivateFeedback(privateFeedbackId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/privateFeedback/" + privateFeedbackId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 * EVALUATION VH DASHBOARD
	 */

	getEvaluationPeriodById(periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/periodById";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationVHByUser(periodId, userId, puestoId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						userId,
						periodId,
						puestoId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationVHByUser";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationVHByEvaluator(periodId, evaluatorId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						periodId,
						evaluatorId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationVHByEvaluator";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getBlockByEvaluationPerson(personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/blockByEvaluationPerson";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	findAllEvaluationVHHistoryByUser(userId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						userId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/findAllEvaluationVHHistoryByUser";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 * EVALUATION VH FORM
	 */

	getEvaluationVHPartialResultsByEvaluator(evalId, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						evalId,
						personId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationVHPartialResultsByEvaluator";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPreviousElementResult(params, userId, elementId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						formId: params.formId,
						personId: params.personId,
						periodId: params.periodId,
						lastIteration: params.iterationNumber,
						userId: userId,
						elementId: elementId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/previousElementResults";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getFilesFromEvaluation(evalId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						evalId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/filesFromEvaluationPartial";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	uploadFile(elementId, partial, formData): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					element_id: elementId,
					partial_id: partial.id,
					person_id: partial.evaluatorId,
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/uploadFile";
			this.http.post(url, formData, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	removeFile(fileId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/removeFile/" + fileId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	/**
	 * EVALUATION VH JOB SECTIONS
	 */

	getJobSections(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/jobSections";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getJobSubsections(parentId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/jobSubsections/" + parentId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getActivePolls(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/activePolls";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPastPolls(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/pastPolls";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	savePoll(body: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/savePoll";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPendings(person_id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/pendigEvaluationFromUser/" + person_id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getPendingsPoll(person_id): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/pendigPoolFromUser/" + person_id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getFormaciones(puesto: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						puesto,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/formaciones/";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getRegistroTandem(perosnId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getRegistroTandem/" + perosnId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	sendFormacion(personID, formacion): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const _body = {
				personID,
				formacion,
			};

			const url = environment.urlDomain + "/api/v1/evaluation/sendFormacion/";
			this.http.post(url, _body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getEvaluationResults(personId, periodId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
						periodId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/evaluationResult/";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getCalendarioTandem(jefe, m, a) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getCalendarTandem/" + m + "/" + a + "/" + jefe;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getLinkEvaluation(ev) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getLinkEvaluation/" + ev;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getFormacionesResults(ev) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getFormacionesResults/" + ev;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
	getDataIberext(periodId, personId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/getFormacionesResults/" + periodId + "/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	//NOTAS IBEREXT

	getAnnotationes(limit: number, pagina: number, personId: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.allperiod != null && filtros.allperiod !== "") options.params = options.params.set("allperiod", filtros.allperiod);
			if (filtros.period != null && filtros.period !== "") options.params = options.params.set("period", filtros.period);

			const url = environment.urlDomain + "/api/v1/evaluation/annotations/" + personId + "/" + limit + "/" + pagina;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
	getAnnotationesTotal(personId: number, filtros: any) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.allperiod != null && filtros.allperiod !== "") options.params = options.params.set("allperiod", filtros.allperiod);
			if (filtros.period != null && filtros.period !== "") options.params = options.params.set("period", filtros.period);

			const url = environment.urlDomain + "/api/v1/evaluation/annotationsTotal/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	deleteNota(notaId: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/annotations/" + notaId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	updateNota(notaId: number, nota) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/annotation/" + notaId;
			this.http.put(url, { nota }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	postNota(body) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/evaluation/annotation/";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getCompliance(personId: number, formId: number, periodId: number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			const url = environment.urlDomain + `/api/v1/evaluation/getCompliance/${personId}/${formId}/${periodId}`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getObjetivosPeriodRevision() {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + `/api/v1/evaluation/getObjetivosPeriodRevision`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getColaboradoresNow() {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + `/api/v1/evaluation/getColaboradoresNow`;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	duplicarPDABlue(planId: Number) {
		//Devuelve todas las bonificaciones del proyecto que cumplan con los filtros que deseamos
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			const url = environment.urlDomain + `/api/v1/evaluation/duplicarPDABlue/${planId}`;
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
}
